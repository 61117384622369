import React, { useState } from "react";

interface NumerologyFormProps {
  onSubmit: (data: { name: string; birthdate: string }) => void;
}

const NumerologyForm: React.FC<NumerologyFormProps> = ({ onSubmit }) => {
  const [name, setName] = useState<string>("");
  const [birthdate, setBirthdate] = useState<string>("");

  // Function to play different error sounds based on input
  const playErrorSound = (type: "name" | "birthdate" | "general") => {
    let audioFile = "/assets/audio/error.mp3"; // Default general error

    if (type === "name") audioFile = "/assets/audio/error_name.mp3";
    if (type === "birthdate") audioFile = "/assets/audio/error_birthdate.mp3";

    const audio = new Audio(audioFile);
    audio.play();
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim() && !birthdate.trim()) {
      playErrorSound("general"); // Play general error if both fields are empty
      return;
    }
    if (!name.trim()) {
      playErrorSound("name"); // Play error for missing name
      return;
    }
    if (!birthdate.trim()) {
      playErrorSound("birthdate"); // Play error for missing birthdate
      return;
    }

    onSubmit({ name, birthdate });
  };

  return (
    <div className="form-wrapper">
      <div className="input-glow"></div>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="mb-4">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your full name"
            className="form-input"
          />
        </div>
        <div className="mb-4">
          <label className="form-label">Birthdate</label>
          <input
            type="date"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
            className="form-input"
          />
        </div>
        <button type="submit" className="glow-button">Calculate</button>
      </form>
    </div>
  );
};

export default NumerologyForm;
