import React, { useEffect, useCallback, useRef } from "react";
import PersonalReadingForm from "./PersonalReadingForm";
import {
  calculateLifePathNumber,
  calculateExpressionNumber,
} from "../utils/numerology";

interface NumerologyResultProps {
  name: string;
  birthdate: string;
  onReset: () => void;
  volume: number;
}

const NumerologyResult: React.FC<NumerologyResultProps> = ({
  name,
  birthdate,
  onReset,
  volume,
}) => {
  const lifePathNumber = calculateLifePathNumber(birthdate);
  const expressionNumber = calculateExpressionNumber(name);

  // 🔹 Store references to the Life Path & Expression Audio
  const lifePathAudioRef = useRef<HTMLAudioElement | null>(null);
  const expressionAudioRef = useRef<HTMLAudioElement | null>(null);

  // 🔹 Play Life Path first, then Expression (No Overlapping)
  const playVoiceSequentially = useCallback(
    (lifePath: number, expression: number) => {
      if (lifePathAudioRef.current) {
        lifePathAudioRef.current.pause();
        lifePathAudioRef.current.currentTime = 0;
      }
      if (expressionAudioRef.current) {
        expressionAudioRef.current.pause();
        expressionAudioRef.current.currentTime = 0;
      }

      // Create new instances for fresh playback
      lifePathAudioRef.current = new Audio(
        `/assets/audio/life_path_male/life_path_${lifePath}.mp3`
      );
      expressionAudioRef.current = new Audio(
        `/assets/audio/expression_female/expression_${expression}.mp3`
      );

      // Set volume before playing
      lifePathAudioRef.current.volume = volume;
      expressionAudioRef.current.volume = volume;

      lifePathAudioRef.current
        .play()
        .catch((error) => console.error("❌ Life Path audio failed:", error));
      lifePathAudioRef.current.onended = () => {
        expressionAudioRef.current
          ?.play()
          .catch((error) =>
            console.error("❌ Expression audio failed:", error)
          );
      };
    },
    []);

     const stopAudio = () => {
       if (lifePathAudioRef.current) {
         lifePathAudioRef.current.pause();
         lifePathAudioRef.current.currentTime = 0;
       }
       if (expressionAudioRef.current) {
         expressionAudioRef.current.pause();
         expressionAudioRef.current.currentTime = 0;
       }
     };

  // 🔹 Play error sounds if inputs are missing
  const playErrorSound = useCallback(
    (type: "name" | "birthdate" | "general") => {
      let audioFile = "/assets/audio/error.mp3"; // Default general error

      if (type === "name") audioFile = "/assets/audio/error_name.mp3";
      if (type === "birthdate") audioFile = "/assets/audio/error_birthdate.mp3";

      const audio = new Audio(audioFile);
      audio.volume = volume; // Apply volume setting

      audio
        .play()
        .catch((error) => console.error("❌ Error sound failed:", error));
    },
    [volume]
  );


useEffect(() => {
  if (lifePathAudioRef.current && !lifePathAudioRef.current.paused) {
    lifePathAudioRef.current.volume = volume;
  }
  if (expressionAudioRef.current && !expressionAudioRef.current.paused) {
    expressionAudioRef.current.volume = volume;
  }
}, [volume]);
 // 🔹 Play Sounds Only If Inputs Are Valid
 useEffect(() => {
   if (lifePathNumber && expressionNumber) {
     playVoiceSequentially(lifePathNumber, expressionNumber);
   }
 }, [lifePathNumber, expressionNumber, playVoiceSequentially]);




  // 🔹 Full Meanings for Life Path Numbers
  const lifePathMeanings: { [key: number]: string } = {
    1: "Born to lead, ambitious, and independent. You're a pioneer with a strong drive to succeed.",
    2: "The diplomat. You seek peace, balance, and meaningful connections with others.",
    3: "Creative and expressive. You bring joy to the world with your words, art, or music.",
    4: "A natural builder. Hardworking and reliable, you create strong foundations in life.",
    5: "Adventurous and free-spirited. You thrive on change, travel, and new experiences.",
    6: "A nurturer. You're deeply connected to family, responsibility, and service to others.",
    7: "A seeker of truth. Deeply spiritual, analytical, and always searching for wisdom.",
    8: "The powerhouse. You are driven toward success, wealth, and achieving big goals.",
    9: "A humanitarian. You seek to improve the world through compassion and generosity.",
    11: "An old soul. Highly intuitive, spiritual, and a visionary who inspires others.",
    22: "The master builder. You can turn dreams into reality through determination.",
    33: "The spiritual teacher. A compassionate leader who uplifts humanity.",
  };

  // 🔹 Full Meanings for Expression Numbers
  const expressionMeanings: { [key: number]: string } = {
    1: "A strong leader with a natural ability to inspire and take charge of any situation.",
    2: "A peacemaker with a gift for bringing harmony and cooperation to groups.",
    3: "Highly expressive, charismatic, and gifted in communication and creativity.",
    4: "Logical and disciplined, with an incredible ability to bring structure to ideas.",
    5: "Free-thinking and versatile, with a deep need for excitement and personal freedom.",
    6: "Loving, responsible, and deeply caring. You thrive in nurturing roles.",
    7: "A deep thinker with a love for philosophy, science, and the mysteries of life.",
    8: "A strong entrepreneur, business-minded and highly ambitious.",
    9: "Wise and compassionate, with an innate ability to help and heal others.",
    11: "A deeply intuitive individual with a unique spiritual insight and inspiration.",
    22: "A master at building great things, with vision and unstoppable determination.",
    33: "A powerful teacher, bringing enlightenment and transformation to others.",
  };

  return (
    <div className="results-wrapper">
      <div className="results-glow"></div>
      <div className="results-container">
        {lifePathNumber && expressionNumber ? (
          <>
            <h2 className="results-title">Your Numerology Results</h2>
            <p className="results-text">
              <strong>Life Path Number:</strong> <span>{lifePathNumber}</span> -{" "}
              {lifePathMeanings[lifePathNumber] || "No meaning available."}
            </p>
            <p className="results-text">
              <strong>Expression Number:</strong>{" "}
              <span>{expressionNumber}</span> -{" "}
              {expressionMeanings[expressionNumber] || "No meaning available."}
            </p>
            {/* Add Personal Reading Form */}
            <PersonalReadingForm name={name} birthdate={birthdate} />
          </>
        ) : (
          <p className="error-message">Invalid input. Please try again.</p>
        )}

        {/* Reset Button */}
        <button
          className="reset-button"
          onClick={() => {
            stopAudio(); // Stop any playing audio first
            onReset(); // Then call the reset function in App.tsx
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default NumerologyResult;
