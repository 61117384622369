import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";

interface PersonalReadingFormProps {
  name: string;
  birthdate: string;
}

const PersonalReadingForm: React.FC<PersonalReadingFormProps> = ({
  name,
  birthdate,
}) => {
  const [email, setEmail] = useState("");
  const [question, setQuestion] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const formRef = useRef<HTMLDivElement>(null);

  // Use effect to scroll to the form when it's mounted
  useEffect(() => {
    if (formRef.current) {
      setTimeout(() => {
        formRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });

        // Backup method for Android Chrome
        window.scrollTo({
          top: formRef.current?.offsetTop || 0,
          behavior: "smooth",
        });
      }, 300); // Give a bit more time for the component to fully render
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    // Ensure all environment variables are defined
    if (!serviceId || !templateId || !publicKey) {
      console.error("❌ Missing EmailJS configuration.");
      return;
    }

    // Email.js parameters
    const templateParams = {
      user_name: name,
      user_birthdate: birthdate,
      user_email: email,
      user_question: question,
    };

    console.log("Template Params:", templateParams); // Log the template parameters

    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log("✅ Email Sent Successfully!", response);
        setIsLoading(false);
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("❌ Email Sending Failed:", error);
        console.log("Error details:", error.text);
        setIsLoading(false);
      });
  };

  return (
    <div className="personal-reading-form" ref={formRef}>
      <h3>Personal Reading FORM</h3>
      {submitted ? (
        <p className="confirmation">✅ Your request has been sent!</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <label>Name:</label>
          <input type="text" value={name} readOnly />
          <label>Birthdate:</label>
          <input type="text" value={birthdate} readOnly />
          <label>Email Address:</label>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label>Additional Questions (Optional):</label>
          <textarea
            placeholder="Ask a specific question..."
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Submitting..." : "Submit Request"}
          </button>
        </form>
      )}
    </div>
  );
};

export default PersonalReadingForm;
