import React, { useState, useEffect, useRef } from "react";
import NumerologyForm from "./components/NumerologyForm";
import NumerologyResult from "./components/NumerologyResult";
import { motion } from "framer-motion";
import GalaxyParticles from "./components/GalaxyParticles";

function App() {
  const [results, setResults] = useState<{
    name: string;
    birthdate: string;
  } | null>(null);

  // Load stored results from Local Storage when app starts
  useEffect(() => {
    const storedResults = localStorage.getItem("numerologyResults");
    if (storedResults) {
      setResults(JSON.parse(storedResults));
    }
  }, []);

  // Save results to Local Storage when they change
  useEffect(() => {
    if (results) {
      localStorage.setItem("numerologyResults", JSON.stringify(results));
    }
  }, [results]);

  const [volume, setVolume] = useState(0.5); // Store volume in App.tsx
  const ambientAudioRef = useRef<HTMLAudioElement | null>(null);
  const [isAudioStarted, setIsAudioStarted] = useState(false); // Ensures user interaction

  // 🔹 Function to Start Background Audio After Click
  const startBackgroundAudio = () => {
    if (!ambientAudioRef.current) {
      ambientAudioRef.current = new Audio(
        "/assets/audio/ambient/hollow_space.mp3"
      );
      ambientAudioRef.current.loop = true;
      ambientAudioRef.current.volume = volume;
    }
    ambientAudioRef.current
      .play()
      .then(() => {
        console.log("✅ Background audio is playing!");
        setIsAudioStarted(true); // Mark audio as started
      })
      .catch((error) => console.error("❌ Background audio failed:", error));
  };

  useEffect(() => {
    if (ambientAudioRef.current) {
      ambientAudioRef.current.volume = volume;
    }
  }, [volume]);

  const handleCalculate = (data: { name: string; birthdate: string }) => {
    setResults(data);
  };

  const handleReset = () => {
    setResults(null);
    localStorage.removeItem("numerologyResults");
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      {" "}
      <GalaxyParticles /> {/* Custom floating particles */}
      <div
        className="container"
        style={{ overflow: "visible", height: "auto" }}
      >
        {/* Overflow-hidden wrapper */}
        <div>
          {/* Vortex Background */}
          <div className="vortex-background"></div>
        </div>
        {/* Pulsing Title with Purple Glow */}
        <motion.h2
          className="pulsating-title"
          initial={{ scale: 1 }}
          animate={{ scale: 1.1 }}
          transition={{ repeat: Infinity, duration: 2, repeatType: "reverse" }}
        >
          Numerologist
        </motion.h2>
        {/* Start Audio Button - Only Shows if Audio Hasn't Started */}
        {!isAudioStarted && (
          <button className="start-audio-button" onClick={startBackgroundAudio}>
            🎵 Play BG Audio
          </button>
        )}
        {/* Volume Control (Only One) */}
        <div className="volume-container">
          <label className="volume-label">Volume</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={volume}
            onChange={(e) => setVolume(parseFloat(e.target.value))}
            className="volume-slider"
          />
        </div>
        {/* Content */}
        <div className="z-10">
          {!results ? (
            <NumerologyForm onSubmit={handleCalculate} />
          ) : (
            <NumerologyResult
              {...results}
              onReset={handleReset}
              volume={volume}
            />
          )}
        </div>
        {/* Footer */}
        <footer className="footer">
          <p>
            © {currentYear} EarthCode. All rights reserved. | Crafted by OE
            Studio Team {"  "}
            <a
              href="https://oblivionedge.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-500 underline"
            >
              Built with ❤️.
            </a>
          </p>
        </footer>
      </div>
    </>
  );
}

export default App;
