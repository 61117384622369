// Reduce a number to a single digit or preserve Master Numbers (11, 22, 33)
const reduceToSingleDigit = (num: number): number => {
  while (num >= 10 && ![11, 22, 33].includes(num)) {
    num = num
      .toString()
      .split("")
      .reduce((sum, digit) => sum + parseInt(digit, 10), 0);
  }
  return num;
};

// Calculate Life Path Number from birthdate
export const calculateLifePathNumber = (birthdate: string): number => {
  const [year, month, day] = birthdate.split("-").map(Number);
  const total = year + month + day;
  return reduceToSingleDigit(total);
};

// Calculate Expression Number from name
export const calculateExpressionNumber = (name: string): number => {
  const letterValues: { [key: string]: number } = {
    a: 1,
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
    h: 8,
    i: 9,
    j: 1,
    k: 2,
    l: 3,
    m: 4,
    n: 5,
    o: 6,
    p: 7,
    q: 8,
    r: 9,
    s: 1,
    t: 2,
    u: 3,
    v: 4,
    w: 5,
    x: 6,
    y: 7,
    z: 8,
  };

  const total = name
    .toLowerCase()
    .split("")
    .filter((char) => char.match(/[a-z]/))
    .reduce((sum, char) => sum + (letterValues[char] || 0), 0);

  return reduceToSingleDigit(total);
};
