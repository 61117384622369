import React, { useEffect, useRef } from "react";
import "./GalaxyParticles.css";

const GalaxyParticles: React.FC = () => {
  const particleContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!particleContainerRef.current) return;

    const createParticle = () => {
      if (!particleContainerRef.current) return;

      const particle = document.createElement("div");
      particle.className = "particle";

      // Set random position
      particle.style.left = `${Math.random() * 100}vw`;
      particle.style.top = `${Math.random() * 100}vh`;

      // Set random size variation for realism
      const size = Math.random() * 5 + 2; // Between 2px and 7px
      particle.style.width = `${size}px`;
      particle.style.height = `${size}px`;

      // Add slight horizontal drift
      particle.style.animationDuration = `${4 + Math.random() * 3}s`; // Between 4s and 7s
      particle.style.animationDelay = `${Math.random() * 2}s`; // Random start delay

      particleContainerRef.current.appendChild(particle);

      setTimeout(() => {
        particle.remove();
      }, 7000);
    };

    const interval = setInterval(createParticle, 400);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <div className="particle-container" ref={particleContainerRef}></div>;
};

export default GalaxyParticles;
